import '../../_rollupPluginBabelHelpers-15308e59.js';
import { useState, useContext, useCallback, useMemo, useEffect } from 'react';
import { ModalContext } from './modal-context.js';

function* getKey() {
  let key = 0;
  while (true) {
    yield `${key++}`;
  }
}
const keyIterator = getKey();
function useModal(ModalComponent, additionalProps) {
  if (additionalProps === void 0) {
    additionalProps = {};
  }
  const [isOpen, setIsOpen] = useState(false);
  const context = useContext(ModalContext);
  const show = useCallback(() => setIsOpen(true), []);
  const hide = useCallback(() => setIsOpen(false), []);
  const key = useMemo(() => keyIterator.next().value || '', []);
  useEffect(() => {
    if (isOpen) {
      context.showModalComponent({
        key,
        Modal: ModalComponent,
        props: additionalProps,
        onClose: hide
      });
    } else {
      context.hideModalComponent(key);
    }
    return () => context.hideModalComponent(key);
  }, [isOpen, key]);
  return [show, hide];
}

export { useModal };
