import { _ as _objectSpread2 } from './_rollupPluginBabelHelpers-6d49b19d.js';

const proxyConfig = {};
const resetProxyConfig = () => {
  proxyConfig.proxyAddress = null;
  proxyConfig.siteId = null;
  proxyConfig.token = null;
  proxyConfig.deviceGuid = null;
  proxyConfig.companyGuid = null;
  proxyConfig.protocol = null;
  proxyConfig.proxyEnabled = false;
};
const setProxyConfig = config => {
  proxyConfig.proxyAddress = config.proxyAddress;
  proxyConfig.siteId = config.siteId;
  proxyConfig.token = config.token;
  proxyConfig.deviceGuid = config.deviceGuid;
  proxyConfig.companyGuid = config.companyGuid;
  proxyConfig.protocol = config.protocol;
  proxyConfig.proxyEnabled = !!config.proxyAddress;
};
const getProxyConfig = () => {
  return _objectSpread2({}, proxyConfig);
};

export { getProxyConfig, resetProxyConfig, setProxyConfig };
