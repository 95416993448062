import '../constants/session-storage-constants.js';
import '../constants/xml-action-constant.js';
import '../constants/xml-tag-constant.js';
import '../types/Auth.js';
import '../_rollupPluginBabelHelpers-6d49b19d.js';
import 'xml2js';
import { buildXMLCommand } from '../helpers/xml-helpers.js';
import '../helpers/session-token-helpers.js';
import '../helpers/trim-url-ending.js';
import '../helpers/metadata-helpers.js';
import '../helpers/header-helpers.js';
import '../helpers/is-allowed-server-error.js';
import '../helpers/proxy-helpers.js';
import '../helpers/xml-error.js';
import '../proxy-config.js';
import { fetchXML } from './fetch-xml.js';

async function fetchXMLMultipleFileData(_ref) {
  let {
    url,
    attributes,
    multipleB64
  } = _ref;
  let xml = buildXMLCommand({
    attributes
  });
  xml = xml.replace('/>', '>');
  xml += `${multipleB64}</cmd>`;
  return fetchXML({
    body: xml,
    url
  });
}

export { fetchXMLMultipleFileData };
