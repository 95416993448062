import React from 'react';
import { FoodGroups } from '@danfoss/etui-sm-xml';

export type ConfigurationMenuContextProps = {
  foodGroup: FoodGroups;
  onSetFoodGroup: (foodgroup: FoodGroups) => void;
  searchMode: boolean;
  searchValue: string;
  onSetSearchValue: (value: string) => void;
};

export const ConfigurationMenuContext = React.createContext(
  null as ConfigurationMenuContextProps | null,
);
