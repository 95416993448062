import * as React from 'react';
import { Div } from '@danfoss/etui-system-elements';
import { useTheme } from '@danfoss/etui-system';
import { BellFilled, Defrost } from '@danfoss/etui-icons';
import { ValueContainer } from './ValueContainer';

export interface ValueGraphicsContainerProps {
  width: string;
  value: string;
  isDefrosting?: boolean;
  hasAlarm?: boolean;
}

export function ValueGraphicsContainer({
  width,
  value,
  isDefrosting = false,
  hasAlarm = false,
}: ValueGraphicsContainerProps) {
  const theme = useTheme();
  return (
    <>
      <ValueContainer width={width}>{value || '-'}</ValueContainer>

      {isDefrosting ? (
        <Defrost
          fontSize={20}
          style={{ marginLeft: 'auto', marginRight: 0 }}
          color={theme.palette.defrost.main}
        />
      ) : null}
      {hasAlarm ? (
        <BellFilled
          fontSize={16}
          style={{ marginLeft: isDefrosting ? '0%' : 'auto', marginRight: 0 }}
          color={theme.palette.brand.light}
        />
      ) : null}
      <Div
        position="absolute"
        bottom="0"
        left="0"
        width="100%"
        height="3px"
        bg={hasAlarm ? theme.palette.brand.light : theme.palette.defrost.main}
      />
    </>
  );
}
