import { resolve } from 'path';
import { paths } from './paths.js';
import { readFileSync, readdirSync } from 'fs';
import { loadAsync } from 'jszip';

function _toPrimitive(t, r) {
  if ("object" != typeof t || !t) return t;
  var e = t[Symbol.toPrimitive];
  if (void 0 !== e) {
    var i = e.call(t, r || "default");
    if ("object" != typeof i) return i;
    throw new TypeError("@@toPrimitive must return a primitive value.");
  }
  return ("string" === r ? String : Number)(t);
}
function _toPropertyKey(t) {
  var i = _toPrimitive(t, "string");
  return "symbol" == typeof i ? i : i + "";
}
function _defineProperty(obj, key, value) {
  key = _toPropertyKey(key);
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value: value,
      enumerable: true,
      configurable: true,
      writable: true
    });
  } else {
    obj[key] = value;
  }
  return obj;
}

/**
 * Edf files class representation
 *
 */
class EdfFiles {
  static async loadEd4File(fileName) {
    const ed4ZipFilePath = resolve(paths.filesEdfFolder, fileName);
    const ed4ZipFileBuffer = readFileSync(ed4ZipFilePath);
    const ed4ZipFile = await loadAsync(ed4ZipFileBuffer);
    const edfFile = await ed4ZipFile.file('edffile.ed3').async('string');
    return edfFile;
  }
  static async loadEdfFile(fileName) {
    try {
      const filesEdfFolderPath = resolve(paths.filesEdfFolder, fileName);
      const [, ext] = fileName.split('.');
      let edfFile = '';
      if (ext === 'ed3') {
        edfFile = readFileSync(filesEdfFolderPath, 'utf8');
      }
      if (ext === 'ed4') {
        edfFile = await this.loadEd4File(fileName);
      }
      this.edfFilesMap.set(fileName, edfFile);
    } catch (error) {
      if (error.code === 'MODULE_NOT_FOUND') {
        throw new Error(`EDF file ${fileName} does not exists`);
      }
      throw new Error(`EDF file ${fileName} is invalid see output ${error}`);
    }
  }
  static async getEdfFile(fileName) {
    if (!this.edfFilesMap.has(fileName)) {
      await this.loadEdfFile(fileName);
    }
    return this.edfFilesMap.get(fileName);
  }
  static getAllEdfFileNames() {
    const edfFilesFolderPath = resolve(paths.filesEdfFolder);
    const filenames = readdirSync(edfFilesFolderPath);
    return filenames;
  }
}
_defineProperty(EdfFiles, "edfFilesMap", new Map());

export { EdfFiles as E, _defineProperty as _ };
