import '../../_rollupPluginBabelHelpers-543c173f.js';
import { v4 } from 'uuid';
import '../Conditions/utils/execute-operation.js';
import '../Conditions/Condition.js';
import '../utils/bind-methods.js';
import '../utils/Subscribable.js';
import { ConditionCollection } from '../Conditions/ConditionCollection.js';
import '../Mappings/Mapping.js';
import { MappingCollection } from '../Mappings/MappingCollection.js';
import { DataSource } from '../DataSource/DataSource.js';
import { Display } from '../Display/Display.js';

class Rule {
  constructor(name, ruleData) {
    this.id = v4();
    this.name = name;
    this.hidden = false;
    this.ruleData = ruleData;
    this.conditions = new ConditionCollection();
    this.mappings = new MappingCollection();
    this.dataSource = new DataSource();
    this.display = new Display();
    this.styles = {};
  }
  getOrder() {
    return this.ruleData.order;
  }
  getData() {
    return {
      hidden: this.hidden,
      ruleData: this.ruleData,
      conditions: this.conditions,
      mappings: this.mappings,
      dataSource: this.dataSource,
      display: this.display
    };
  }
  isHidden() {
    return this.hidden;
  }
  setHidden(hide) {
    this.hidden = hide;
  }
  setOrder(order) {
    this.ruleData.order = order;
  }
  import(rawObj) {
    if (rawObj.id) {
      this.id = rawObj.id;
    }
    if (rawObj.name) {
      this.name = rawObj.name;
    }
    if (rawObj.hidden) {
      this.hidden = rawObj.hidden;
    }
    if (rawObj.order) {
      this.ruleData.order = rawObj.order;
    }
    if (rawObj.description) {
      this.ruleData.description = rawObj.description;
    }
    if (rawObj.conditions) {
      this.conditions = this.conditions.importConditions(rawObj.conditions);
    }
    if (rawObj.mappings) {
      this.mappings = this.mappings.importMappings(rawObj.mappings);
    }
    if (rawObj.dataSource) {
      this.dataSource = this.dataSource.import(rawObj.dataSource);
    }
    if (rawObj.display) {
      this.display = this.display.import(rawObj.display);
    }
    if (rawObj.styles) {
      this.styles = rawObj.styles;
    }
    return this;
  }
  getStyles() {
    return this.styles;
  }
  setStyles(field, value) {
    this.styles[field] = value;
  }
  static defaultData() {
    return {
      order: 0,
      description: ''
    };
  }
}

export { Rule };
