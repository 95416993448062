import { _ as _objectSpread2 } from '../../../../../_rollupPluginBabelHelpers-15308e59.js';
import { XML_ACTION, buildXMLCommand, XML_TAG, fetchXML } from '@danfoss/etui-sm-xml';

async function fetchUploadFile(url, user, fileChunksArray, _, fileSize, done, index, fileId, setProgressCallback, isDBUpdateFlow, isS55ToSM800A) {
  if (done === void 0) {
    done = 0;
  }
  if (index === void 0) {
    index = 1;
  }
  const offset = fileSize;
  const size = fileChunksArray.length;
  let body = '';
  const fileName = isDBUpdateFlow ? isS55ToSM800A ? 'smDatabase.s55' : 'smDatabase.7z' : 'smDownload.7z';
  const baseAttributes = {
    action: XML_ACTION.LOAD_FILE,
    user: user.user,
    password: user.password,
    filename: fileName,
    size,
    offset,
    index,
    done
  };
  if (fileChunksArray.length === 1) {
    done = 1;
    body = buildXMLCommand({
      attributes: _objectSpread2(_objectSpread2({}, baseAttributes), {}, {
        done
      }),
      items: [{
        tag: XML_TAG.B64,
        value: fileChunksArray[index - 1]
      }]
    });
  } else if (index === 1) {
    body = buildXMLCommand({
      attributes: _objectSpread2({}, baseAttributes),
      items: [{
        tag: XML_TAG.B64,
        value: fileChunksArray[index - 1]
      }]
    });
  } else if (index === fileChunksArray.length) {
    done = 1;
    body = buildXMLCommand({
      attributes: _objectSpread2(_objectSpread2({}, baseAttributes), {}, {
        done,
        file_id: fileId
      }),
      items: [{
        tag: XML_TAG.B64,
        value: fileChunksArray[index - 1]
      }]
    });
  } else {
    body = buildXMLCommand({
      attributes: _objectSpread2(_objectSpread2({}, baseAttributes), {}, {
        file_id: fileId
      }),
      items: [{
        tag: XML_TAG.B64,
        value: fileChunksArray[index - 1]
      }]
    });
  }
  const response = await fetchXML({
    url,
    body
  });
  if (done !== 1) {
    const {
      file_id
    } = response;
    index++;
    setProgressCallback(index, fileChunksArray);
    return fetchUploadFile(url, user, fileChunksArray, fileName, fileSize, done, index, file_id, setProgressCallback, isDBUpdateFlow);
  }
  return response;
}

export { fetchUploadFile };
