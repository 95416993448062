import '../_rollupPluginBabelHelpers-15308e59.js';
import '@danfoss/etui-sm-xml';
import '../types/DeviceGrouped.js';
import './compose-device-id.js';
import './compose-rack-id.js';
import './get-is-suction-device.js';
import './get-is-no-compressor-device.js';
import './get-is-pack-device.js';
import './get-is-rack-device.js';
import './get-is-top-level-device.js';
import { a as getRackGroups } from '../get-rack-groups-6c00696c.js';
import '@danfoss/etui-core';
import './get-normalized-suction.js';
import { getSuctionGroups } from './get-suction-groups.js';

function getGroupedRefrigerationDevices(deviceType, devices) {
  const rootChildren = getSuctionGroups(deviceType, devices);
  return getRackGroups(deviceType, devices, rootChildren);
}

export { getGroupedRefrigerationDevices };
