import '../utils/read-file-as-url-data.js';
import { parseXml } from '../utils/parse-xml.js';
import { isEncoded } from '../utils/is-encoded.js';
import 'pako';
import { decode } from '../utils/decode.js';
import { fetchImageBase64String } from '../utils/fetch-image-base64-string.js';
import { getXml } from '../utils/get-xml.js';
import '../_rollupPluginBabelHelpers-543c173f.js';
import { v4 } from 'uuid';
import '../annotation/Conditions/utils/execute-operation.js';
import '../annotation/Conditions/Condition.js';
import '../annotation/utils/bind-methods.js';
import '../annotation/utils/Subscribable.js';
import '../annotation/Conditions/ConditionCollection.js';
import '../annotation/Mappings/Mapping.js';
import '../annotation/Mappings/MappingCollection.js';
import '../annotation/DataSource/DataSource.js';
import '../annotation/Display/Display.js';
import '../annotation/Rules/Rule.js';
import { RuleCollection } from '../annotation/Rules/RuleCollection.js';

const VIEW_STUB_IMAGE = 'scada-view-image-extractor-stub-img.png';
class ScadaStorageView {
  constructor(name) {
    this.name = name;
    this.id = v4();
    this._xml = '';
    this.xmlDoc = undefined;
    this.mxCellCollection = [];
    this.rules = new RuleCollection();
    this.sortIndex = '';
    this.subViews = [];
    this.createdAt = Date.now();
  }
  set xml(xml) {
    if (isEncoded(xml)) {
      xml = decode(xml, true, true, true);
    }
    this._xml = xml;
    if (this._xml) {
      this.xmlDoc = parseXml(this._xml);
      this.mxCellCollection = this.xmlDoc.getElementsByTagName('mxCell');
    }
  }
  get xml() {
    return this._xml;
  }
  importView(rawObj) {
    if (rawObj.name) {
      this.name = rawObj.name;
    }
    if (rawObj.id) {
      this.id = rawObj.id;
    }
    if (rawObj.xml || rawObj._xml) {
      this.xml = rawObj.xml || rawObj._xml;
    }
    if (rawObj.rules) {
      this.rules.importRules(rawObj.rules);
    }
    if (rawObj.createdAt) {
      this.createdAt = rawObj.createdAt;
    }
    if (rawObj.updatedAt) {
      this.updatedAt = rawObj.updatedAt;
    }
    if (rawObj.subViews) {
      if (Array.isArray(rawObj.subViews) && rawObj.subViews.length > 0) {
        this.subViews = rawObj.subViews.map(subViewConfig => {
          const newView = new ScadaStorageView('');
          newView.importView(subViewConfig);
          return newView;
        });
      }
    }
    if (rawObj.sortIndex) {
      this.sortIndex = rawObj.sortIndex;
    }
  }
  exportView() {
    return {
      id: this.id,
      name: this.name,
      xml: this.xml,
      rules: this.rules.getRules(),
      createdAt: this.createdAt,
      updatedAt: this.updatedAt,
      sortIndex: this.sortIndex,
      subViews: this.subViews
    };
  }
  getMxCellIdsList() {
    const list = [];
    for (const mxCell of this.mxCellCollection) {
      const mxCellId = mxCell.getAttribute('id');
      if (mxCellId) {
        list.push(mxCellId);
      }
    }
    return list;
  }
  async replaceUrlImagesWithBase64() {
    if (!this.xml || this.mxCellCollection.length === 0 || !this.xmlDoc) {
      return;
    }
    const imageUrlTestRx = /(https?:\/\/.*?;)/i;
    const replaceImageTestRx = /(image=https?:\/\/.*?;)/gi;
    for await (const mxCell of this.mxCellCollection) {
      // @ts-ignore
      let style = mxCell === null || mxCell === void 0 ? void 0 : mxCell.getAttribute('style');
      if (style !== null) {
        const imageUrlExists = imageUrlTestRx.exec(style);
        if (imageUrlExists !== null) {
          const [imageUrl] = imageUrlExists;
          const imageUrlWithoutSemicolon = imageUrl.slice(0, -1);
          let imgBase64 = await fetchImageBase64String(imageUrlWithoutSemicolon);
          if (imgBase64 === null) {
            // load stub image
            imgBase64 = await fetchImageBase64String(VIEW_STUB_IMAGE);
            style = `${style}imageWidth=210;imageHeight=30;`;
          }

          // @ts-ignore
          imgBase64 = imgBase64.replace(';base64', '');
          style = style.replace(replaceImageTestRx, `image=${imgBase64};`);
          // @ts-ignore
          mxCell === null || mxCell === void 0 ? void 0 : mxCell.setAttribute('style', style);
        }
      }
    }
    this.xml = getXml(this.xmlDoc);
  }
  getParameterIdsByShapeId(shapeId) {
    return this.rules.getRules().filter(rule => rule.mappings.getMappings().some(mpg => mpg.shapeIdentifier === shapeId)).map(rule => rule.dataSource.getSourceId()) || [];
  }
}

export { ScadaStorageView };
