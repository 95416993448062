import { DeviceGrouped, DeviceType } from '@danfoss/etui-sm';
import { Device, getValidDeviceData } from '@danfoss/etui-sm-xml';

type DeviceData = {
  [key in DeviceType]?: DeviceGrouped[];
};

export function getValidDeviceGroupData(data: DeviceData): DeviceData {
  const validMiscDeviceGroups = data?.misc?.map(deviceGroupItem => ({
    ...deviceGroupItem,
    items: deviceGroupItem.items
      ? getValidDeviceData(deviceGroupItem.items)
      : deviceGroupItem.items,
  }));
  data.misc = validMiscDeviceGroups as DeviceGrouped<Device>[];
  return data;
}
