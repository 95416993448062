import { env } from '../env';

const getEnvValue = variable => {
  return env[variable];
};

const { host, pathname } = window.location;

// Values
const azureToken = getEnvValue('REACT_APP_AZURE_TOKEN');
const azureAccount = getEnvValue('REACT_APP_AZURE_ACCOUNT');
const storageType = getEnvValue('REACT_APP_SCADA_STORAGE');
const targetAccessLevel = getEnvValue('REACT_APP_TARGET_ACCESS_LEVEL');
const desktopVersionUrl =
  getEnvValue('REACT_APP_ARCHIVE_URL') ||
  `https://${host}${pathname.slice(0, -1)}`;
const featureHubApiKey = getEnvValue('REACT_APP_FEATURE_HUB_API_KEY');
const featureHubUrl = getEnvValue('REACT_APP_FEATURE_HUB_URL');
const proxyUserApiUrl = getEnvValue('REACT_APP_PROXY_USER_API_URL');
const SVW_VERSION = getEnvValue('REACT_APP_SVW_VERSION');

// Flags
const isElectron = getEnvValue('REACT_APP_IS_ELECTRON_PACKAGE') === 'true';
const isProd = getEnvValue('NODE_ENV') === 'production';
const isDevMode = getEnvValue('NODE_ENV') === 'development';
const isTestMode = getEnvValue('NODE_ENV') === 'test';
const isReadOnlyMode = getEnvValue('REACT_APP_READ_ONLY_MODE') === 'true';
const isExperimental = getEnvValue('REACT_APP_EXPERIMENTAL') === 'true';
const isAzureStorage = storageType === 'azure';
const isXmlStorage = storageType === 'xml';

// Links
const STOREVIEW_WEB_HOMEPAGE_URL =
  'https://www.danfoss.com/en/products/dcs/monitoring-and-services/storeview-web/';
const ALSENSE_PORTAL_URL = 'https://alsenseportal.danfoss.com';
const DESKTOP_INSTALLER_URL = `${desktopVersionUrl}/downloads/desktop/Danfoss-StoreViewWeb.exe`;
const EULA_URL = `https://www.danfoss.com/en/service-and-support/downloads/dcs/storeview-web-eula/#tab-overview`;
const SECURITY_URL = `https://www.danfoss.com/en/service-and-support/downloads/dcs/adap-kool-software/ak-sm-800a/#tab-security`;
const FAQ_SECURE_CONNECTION_URL = `https://www.danfoss.com/en/products/dcs/monitoring-and-services/storeview-web/#tab-faq`;

export {
  isDevMode,
  isTestMode,
  isProd,
  isExperimental,
  isAzureStorage,
  isXmlStorage,
  isElectron,
  azureToken,
  azureAccount,
  storageType,
  featureHubApiKey,
  featureHubUrl,
  targetAccessLevel,
  desktopVersionUrl,
  getEnvValue,
  proxyUserApiUrl,
  isReadOnlyMode,
  STOREVIEW_WEB_HOMEPAGE_URL,
  ALSENSE_PORTAL_URL,
  DESKTOP_INSTALLER_URL,
  EULA_URL,
  SECURITY_URL,
  FAQ_SECURE_CONNECTION_URL,
  SVW_VERSION,
};
