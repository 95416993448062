import { Device, fetchDevicesByUnits, Unit, User } from '@danfoss/etui-sm-xml';
import { DeviceType, DeviceGrouped } from '@danfoss/etui-sm/types';
import { getGroupedDevicesByDeviceType } from '@danfoss/etui-sm/utils';
import { useFeatureFlag } from 'hooks/featurehub/FeatureHubContext';
import { fetchDeviceConfigurationByUnit } from 'pages/ConfigurationPage';
import { fetchDeviceConfigurationByUnit as fetchDeviceConfigurationByUnitOld } from 'pages/ConfigurationPageOld';
import { getXmlDeviceListByDeviceType } from 'utils';

async function fetchGroupedDevicesByDeviceType([
  url,
  deviceType,
  user,
  units,
  skipSessionUpdate = false,
]: [string, DeviceType, User, Unit[], boolean?]) {
  const data = await fetchDevicesByUnits([
    url,
    units,
    getXmlDeviceListByDeviceType(deviceType),
    user,
    skipSessionUpdate,
  ]);

  return getGroupedDevicesByDeviceType(deviceType, data);
}

async function fetchDeviceStatusTabId(
  sourceUrl: string,
  unit: Unit,
  device: Device,
  user: User,
) {
  const newConfigPageFlag = useFeatureFlag('NewConfigPage');

  return newConfigPageFlag
    ? fetchDeviceConfigurationByUnit([sourceUrl, unit, device, user])
    : fetchDeviceConfigurationByUnitOld([sourceUrl, unit, device, user]);
}

async function fetchGroupedDevicesByScopedDeviceTypes(
  url: string,
  deviceTypes: DeviceType[] = [],
  user: User,
  units: Unit[],
  skipSessionUpdate?: boolean,
) {
  const responses = await Promise.all(
    deviceTypes.map(deviceType =>
      fetchGroupedDevicesByDeviceType([
        url,
        deviceType,
        user,
        units,
        skipSessionUpdate,
      ]),
    ),
  );

  const result: { [key in DeviceType]?: DeviceGrouped[] } = responses.reduce(
    (map, response, index) => {
      const deviceType = deviceTypes[index];
      map[deviceType] = response;
      return map;
    },
    {} as Record<DeviceType, DeviceGrouped[]>,
  );

  return result;
}

async function fetchGroupedDevicesByDeviceTypes([
  url,
  user,
  units,
  skipSessionUpdate = false,
]: [string, User, Unit[], boolean?]) {
  const deviceTypes: DeviceType[] = [
    'refrig',
    'hvac',
    'lights',
    'meter',
    'misc',
  ];

  return fetchGroupedDevicesByScopedDeviceTypes(
    url,
    deviceTypes,
    user,
    units,
    skipSessionUpdate,
  );
}

export {
  fetchGroupedDevicesByDeviceType,
  fetchGroupedDevicesByDeviceTypes,
  fetchGroupedDevicesByScopedDeviceTypes,
  fetchDeviceStatusTabId,
};
