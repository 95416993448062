import { _ as _objectSpread2 } from '../../../_rollupPluginBabelHelpers-15308e59.js';
import { getProxyConfig } from '@danfoss/etui-sm-xml';
import { getArray } from '../../../utils/get-array.js';
import { STORAGE_XML_BACKEND_IP_KEY } from '../../../constants/session-storage-constants.js';
import '../../../constants/software-versions.js';
import 'react-device-detect';
import { getDeviceInfo } from './get-device-info.js';

const compareIp = (ips, port, sessionIp) => {
  return ips.some(ip => sessionIp === ip || sessionIp === `${ip}:${port}`);
};
function getSegmentationValues(units, user, responsiveValues) {
  const sessionIp = sessionStorage.getItem(STORAGE_XML_BACKEND_IP_KEY);
  const unitOfIp = units && (units.length === 1 ? units[0] : units.find(unit => getArray(unit.unit).find(_ref => {
    let {
      ip,
      ip1,
      ip2,
      port
    } = _ref;
    return compareIp([ip, ip1, ip2], port, sessionIp);
  }) || getArray(unit.unit_internet).find(_ref2 => {
    let {
      ip,
      ip1,
      ip2,
      port
    } = _ref2;
    return compareIp([ip, ip1, ip2], port, sessionIp);
  })));
  const {
    sys_model_name: hardwareType,
    software: firmwareType,
    macAddr,
    store_name: storeName,
    city,
    street
  } = unitOfIp || {};
  const {
    user: userName,
    authtype: userLevel,
    language: userLanguage
  } = user || {};
  const getUserProxyId = () => {
    const {
      token
    } = getProxyConfig();
    if (!token) {
      return {};
    }
    try {
      const payloadb64 = token.split('.')[1];
      const {
        sub: proxyUserId
      } = JSON.parse(atob(payloadb64));
      return {
        proxyUserId
      };
    } catch (e) {
      return {};
    }
  };
  return sessionIp ? _objectSpread2(_objectSpread2(_objectSpread2(_objectSpread2({
    ip: sessionIp,
    hardwareType,
    firmwareType,
    macAddr,
    storeName,
    city,
    street,
    userName,
    userLanguage,
    userLevel
  }, responsiveValues), getDeviceInfo()), getProxyConfig()), getUserProxyId()) : null;
}

export { getSegmentationValues };
