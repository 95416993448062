import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Controller, useForm } from 'react-hook-form';
import { useTheme } from '@danfoss/etui-system';
import { Button, Form } from '@danfoss/etui-core';
import { Div, Span } from '@danfoss/etui-system-elements';
import { AmPm, TimeInput } from '../TimeInput';
import { HourFormat } from '../TimeInput/TimeInput';
import { BaseEditProps } from './ConfigurationListItemEdit';
import { ConfigurationListitemEditInputAddon } from './ConfigurationListItemEditInputAddon';
import { buildRelativeTime, buildTime, getIsEventInForm } from './utils';
import { ConfigurationListItemEditDisplayValue } from './ConfigurationListItemEditDisplayValue';
import { defineAmOrPm } from './utils/define-am-or-pm';
import { useConfigItemEdit } from './hooks/useConfigItemEdit';

const ADDON_BG_COLOR = '#f4f4f4';

function getInitialValue(value: string, hourFormat: HourFormat) {
  const [time] = value.split(' ');
  return hourFormat === '12' ? time.replace(/^0/, '') : time;
}

function getAmPmValue(value: string = '') {
  const values = value.replace(/^0/, '').split(' ');
  return values ? values[1] : '';
}

function ConfigurationListItemEditTime({
  index,
  name,
  item,
  onSave,
  onUnsavedChanges,
  isAuthorized,
  addonAnimatonDuration = 2000,
}: BaseEditProps) {
  const node = React.useRef<HTMLDivElement>();
  const theme = useTheme();
  const { t } = useTranslation();
  const [isSucceeded, setIsSucceeded] = React.useState(false);
  const [isAMPMChanged, setIsAMPMChanged] = React.useState(false);
  const { editMode, enableEditMode, disableEditMode } = useConfigItemEdit();
  const {
    control,
    setValue,
    setError,
    getValues,
    errors,
    reset,
    formState,
    handleSubmit,
    clearErrors,
  } = useForm();
  const [isUndoingChanges, setIsUndoingChanges] = React.useState(false);
  const token = item?.token.substring(0, 2) === '10';
  const isRelative = token && item?.type === '256';

  const { isSubmitted, isSubmitting, isDirty, dirtyFields } = formState;
  const hourFormat = item.tf === '1' ? '24' : '12';
  const valueProperty = 'value';
  const initialValue = getInitialValue(item[valueProperty], hourFormat);
  const defaultValues = {
    [name]: initialValue,
  };
  const initialAmPmValue = getAmPmValue(item[valueProperty]);
  const error = errors[name];
  const isFailed = !!error;

  const handleOnUnsavedChanges = () => {
    onUnsavedChanges({
      isOpen: true,
      onSaveChanges: () => {
        handleSubmit(handleOnSubmit)();
      },
      onCancelChanges: () => {
        setIsUndoingChanges(true);
      },
    });
  };

  const handleOnCancelEdit = (event, isOnBlur: boolean = false) => {
    if (isSubmitted || isSubmitting || isSucceeded || isFailed) {
      return;
    }
    if (isUndoingChanges) {
      reset(defaultValues);
      disableEditMode();
      setIsUndoingChanges(false);
      return;
    }

    if (!isOnBlur && node.current.contains(event.target)) {
      return;
    }

    const values = getValues();
    const nextValue = values[name];
    const isFieldDirty = dirtyFields[name];

    if ((isFieldDirty && initialValue !== nextValue) || isAMPMChanged) {
      if (isOnBlur && getIsEventInForm(event)) {
        handleSubmit(handleOnSubmit)();
      } else {
        event.preventDefault();
        if (event.currentTarget instanceof HTMLInputElement) {
          event.currentTarget.focus();
          handleOnUnsavedChanges();
        }
        if (event.currentTarget instanceof Document) {
          const { activeElement } = event.currentTarget;
          activeElement.focus();
          handleOnUnsavedChanges();
        }
      }
    } else {
      reset(defaultValues);
      disableEditMode();
    }
  };

  const handleOnSubmit = async (data: { [key: string]: string }) => {
    const time = data[name];

    const newValue = isRelative
      ? buildRelativeTime(time)
      : [buildTime(time, hourFormat), defineAmOrPm(time, hourFormat)].join(' ');

    if (
      !initialValue ||
      (initialValue && newValue !== initialValue) ||
      isAMPMChanged
    ) {
      try {
        await onSave(item, {
          ival: time.replace(':', ''),
          valueProperty,
          tmpValue: newValue.trim(),
        });
        setIsSucceeded(true);
      } catch (e) {
        setError(name, { type: 'server' });
      }
    }
  };

  React.useEffect(() => {
    reset(defaultValues);
  }, [item[valueProperty]]);

  React.useEffect(() => {
    if (error) {
      setTimeout(() => {
        clearErrors(name);
        reset(defaultValues);
        disableEditMode();
      }, addonAnimatonDuration);
    }

    if (isSucceeded) {
      setTimeout(() => {
        setIsSucceeded(false);
        disableEditMode();
      }, addonAnimatonDuration);
    }

    if (isUndoingChanges) {
      handleOnCancelEdit(null, false);
    }
  }, [error, isSucceeded, isUndoingChanges]);

  return isAuthorized ? (
    <Form
      onSubmit={handleSubmit(handleOnSubmit)}
      styles={{ root: { position: 'relative' } }}
    >
      <div key={index} ref={node}>
        {editMode ? (
          <Controller
            control={control}
            name={name}
            defaultValue={defaultValues[name]}
            render={({ onChange, value }) => (
              <TimeInput
                item={item}
                size="small"
                name={name}
                defaultValue={value}
                defaultAmPm={initialAmPmValue as AmPm}
                autoFocus={true}
                hourFormat={hourFormat}
                disabled={isSubmitting || isSucceeded || isFailed}
                onBlur={event => {
                  if (isDirty || isAMPMChanged) {
                    if (!value) {
                      reset(defaultValues);
                    } else {
                      setValue(name, value);
                      handleOnCancelEdit(event, true);
                    }
                  } else {
                    handleOnCancelEdit(event, true);
                  }
                }}
                onTimeChange={({ value: newValue, display: newDisplay }) => {
                  const newAMPMValue = getAmPmValue(newDisplay);
                  const formattedValue = getInitialValue(newValue, hourFormat);
                  setIsAMPMChanged(initialAmPmValue !== newAMPMValue);
                  setValue(name, formattedValue);
                  onChange(formattedValue);
                  isRelative && handleSubmit(handleOnSubmit)();
                }}
                endInputAddon={
                  isSubmitting || isSucceeded || isFailed ? (
                    <ConfigurationListitemEditInputAddon
                      isSubmitting={isSubmitting}
                      isSucceeded={isSucceeded}
                      isFailed={isFailed}
                      styles={{
                        inputAddon: {
                          root: isSubmitting
                            ? {
                                bg: ADDON_BG_COLOR,
                                top: '9px',
                                width: '24px',
                                height: '24px',
                                right: `${theme.spacing.xxs}px`,
                              }
                            : {
                                bg: ADDON_BG_COLOR,
                                top: '5px',
                                width: '32px',
                                height: '32px',
                                right: `${theme.spacing.xxs}px`,
                              },
                        },
                      }}
                    />
                  ) : null
                }
                styles={{
                  label: error
                    ? { color: theme.palette.error.main }
                    : isSucceeded
                    ? { color: theme.palette.success.main }
                    : {},
                  input: error
                    ? { borderColor: theme.palette.error.main }
                    : isSucceeded
                    ? { borderColor: theme.palette.success.main }
                    : {},
                }}
              />
            )}
          />
        ) : (
          <ConfigurationListItemEditDisplayValue
            onClick={enableEditMode}
            onFocus={enableEditMode}
            value={item.value}
          />
        )}
      </div>
      {editMode &&
        (isDirty || isAMPMChanged) &&
        !isSubmitting &&
        !isSucceeded &&
        !isFailed && (
          <Div
            display="flex"
            alignItems="center"
            position="absolute"
            top="0"
            right={['unset', 'unset', '-62px']}
            left={['-82px', '-82px', 'unset']}
            height="42px"
          >
            <Button
              variant="tertiary"
              onMouseDown={() => setIsUndoingChanges(true)}
              small={true}
            >
              {t('t964')}
            </Button>
          </Div>
        )}
    </Form>
  ) : (
    <Span>{item.value}</Span>
  );
}

export { ConfigurationListItemEditTime };
