import * as React from 'react';
import useSWR from 'swr';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@danfoss/etui-system';
import { darken } from '@danfoss/etui-colors';
import { Div } from '@danfoss/etui-system-elements';
import { ConfigurationTabItem, Device, User, Unit } from '@danfoss/etui-sm-xml';
import { ContentError } from '@danfoss/etui-sm';
import { useHistory } from 'react-router-dom';
import {
  DeviceConfigurationBaseCommandBar,
  DeviceConfigurationBaseHeader,
} from '../../../DeviceConfiguration';
import {
  ConfigurationTabByUnitResponse,
  fetchDeviceStatusTabData,
} from '../../actions';
import { ConfigurationItemContentList } from '../ConfigurationItemContentList';

type WidgetProps = {
  xmlBackendUrl: string;
  device: Device;
  user: User;
  unit: Unit;
  tabs: any[];
};

const DeviceConfigurationWidget = React.memo(
  function DeviceConfigurationWidget({
    xmlBackendUrl,
    device,
    user,
    unit,
    tabs = [],
  }: WidgetProps) {
    const theme = useTheme();
    const { t } = useTranslation();

    const [selectedTab, setSelectedTab] = React.useState<ConfigurationTabItem>(
      tabs[0],
    );
    const [group, setGroup] = React.useState('0');
    const [subgroup, setSubgroup] = React.useState('0');
    const [page, setPage] = React.useState('0');

    const {
      location: { pathname },
    } = useHistory();

    const {
      data: { groupnames, subgroupnames, multipage } = {
        groupnames: [],
        subgroupnames: [],
        multipage: '0',
      } as ConfigurationTabByUnitResponse,
      error: deviceDataError,
      mutate,
    } = useSWR(
      () =>
        selectedTab && user && device && unit
          ? [xmlBackendUrl, unit, device, user, selectedTab, pathname]
          : null,
      fetchDeviceStatusTabData,
      {
        revalidateOnFocus: false,
        shouldRetryOnError: true,
      },
    );

    const handleOnTabChange = React.useCallback(
      (value: string) => {
        const newSelectedTab = tabs.find(deviceTab => deviceTab.id === value);
        newSelectedTab && setSelectedTab(newSelectedTab);
      },
      [tabs],
    );

    const shouldShowCommandBar = [
      groupnames.length,
      subgroupnames.length,
      +multipage > 0,
    ].some(Boolean);

    return deviceDataError ? (
      <ContentError
        title={t('t17')}
        message={t('t66')}
        onRetryClick={() => mutate()}
      />
    ) : (
      <Div flex="1">
        <DeviceConfigurationBaseHeader
          activeTabId={selectedTab ? selectedTab.id : null}
          tabs={tabs || []}
          onTabChange={handleOnTabChange}
        />
        <Div
          height="calc(100% - 50px)"
          bg={darken(theme.palette.common.bg, 0.01)}
        >
          {shouldShowCommandBar ? (
            <DeviceConfigurationBaseCommandBar
              groupnames={groupnames}
              subgroupnames={subgroupnames}
              multipage={multipage}
              deviceGroup={group}
              deviceSubgroup={subgroup}
              page={page}
              onSetDeviceGroup={setGroup}
              onSetDeviceSubgroup={setSubgroup}
              onSetPage={setPage}
            />
          ) : null}
          <ConfigurationItemContentList
            menuId={selectedTab.id}
            tab={selectedTab}
            unit={unit}
            device={device}
            deviceGroup={group}
            deviceSubgroup={subgroup}
            page={page}
          />
        </Div>
      </Div>
    );
  },
);

export { DeviceConfigurationWidget };
