import * as React from 'react';
import { ConfigurationModalContext } from './ConfigurationModalContext';

function ConfigurationModalProvider({ children }) {
  const [openModals, setOpenModals] = React.useState([]);

  const toggleModals = (modalName: string) => {
    setOpenModals(prevModals =>
      prevModals.includes(modalName)
        ? prevModals.filter(modal => modal !== modalName)
        : [...prevModals, modalName],
    );
  };

  return (
    <ConfigurationModalContext.Provider
      value={{
        openModals,
        toggleModals,
      }}
    >
      {children}
    </ConfigurationModalContext.Provider>
  );
}

export { ConfigurationModalProvider };
