import '../../constants/regex-patterns-constant.js';
import '../../constants/session-storage-constants.js';
import '../../constants/xml-action-constant.js';
import '../../constants/xml-error-code.js';
import '../../constants/xml-tag-constant.js';
import '../../helpers/alarm-helpers.js';
import '../../types/Auth.js';
import '../../types/Unit.js';
import '../../_rollupPluginBabelHelpers-6d49b19d.js';
import 'xml2js';
import '../../helpers/xml-helpers.js';
import '../../helpers/session-token-helpers.js';
import '../../helpers/trim-url-ending.js';
import '../../helpers/metadata-helpers.js';
import '../../helpers/header-helpers.js';
import '../../helpers/is-allowed-server-error.js';
import '../../helpers/proxy-helpers.js';
import '../../helpers/url-helpers/get-is-valid-dns.js';
import '../../helpers/url-helpers/get-is-valid-ip.js';
import '../../helpers/url-helpers/get-is-valid-ip-or-dns.js';
import '../../helpers/url-helpers/get-is-valid-port.js';
import '../../helpers/url-helpers/get-url-info.js';
import '../../helpers/unit-helpers/initial-unit-utils.js';
import '../../helpers/unit-helpers/get-unit-networks-by-unit.js';
import '../../helpers/unit-helpers/get-unit-network-by-unit.js';
import '../../helpers/unit-helpers/get-unit-url-by-unit-network.js';
import { getUnitUrl } from '../../helpers/unit-helpers/get-unit-url.js';
import '../../helpers/xml-error.js';
import '../../proxy-config.js';
import './fetch-device-alarm-summary.js';
import '../alarm-actions/fetch-alarms-by-refs.js';
import { fetchDeviceAlarms } from './fetch-device-alarms.js';
import 'csvtojson';
import '../../fetch-xml/fetch-xml.js';
import '../../fetch-xml/fetch-xml-data.js';

async function fetchDeviceAlarmsByUnit(_ref) {
  let [sourceUrl, unit, alarmType, user, device] = _ref;
  const url = getUnitUrl(sourceUrl, unit);
  return fetchDeviceAlarms(url, alarmType, user, device);
}

export { fetchDeviceAlarmsByUnit };
