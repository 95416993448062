import { XML_ACTION } from './constants/xml-action-constant.js';
import { XML_TAG } from './constants/xml-tag-constant.js';
import { _ as _objectSpread2 } from './_rollupPluginBabelHelpers-6d49b19d.js';
import './helpers/xml-helpers.js';
import { getUnitUrl } from './helpers/unit-helpers/get-unit-url.js';
import { getFileChunk } from './actions/file-actions.js';
import 'csvtojson';
import { fetchXMLData } from './fetch-xml/fetch-xml-data.js';

const HISTORY_FILENAME = 'history.pge';
const HISTORY_ACCESS_AREA = 'ramdisk_html';
function getHistoryFileChunk(url, user, offset) {
  return getFileChunk(url, user, offset, HISTORY_FILENAME, HISTORY_ACCESS_AREA);
}
function readQueryData(url, user, queryId) {
  return fetchXMLData({
    url,
    attributes: {
      version: 'C',
      storeview_only: '1',
      user: user.user,
      password: user.password,
      lang: user.language,
      action: XML_ACTION.READ_QUERY_DATA,
      query_id: queryId
    }
  });
}
function readHistoryQueryStatus(url, user, queryId) {
  return fetchXMLData({
    url,
    attributes: {
      version: 'C',
      storeview_only: '1',
      user: user.user,
      password: user.password,
      lang: user.language,
      action: XML_ACTION.READ_QUERY_STATUS,
      query_id: queryId
    }
  });
}
function startHistoryQuery(url, user, start, end, historyObjs) {
  const items = historyObjs.map(_ref => {
    let {
      nodetype,
      hist_index
    } = _ref;
    return {
      tag: XML_TAG.POINT,
      attributes: {
        id: hist_index,
        nt: nodetype
      }
    };
  });
  return fetchXMLData({
    url,
    attributes: {
      version: 'C',
      storeview_only: '1',
      user: user.user,
      password: user.password,
      lang: user.language,
      action: XML_ACTION.START_HISTORY_QUERY,
      simple: '1',
      hist_file: '1',
      start_epoch: start.toString(),
      stop_epoch: end.toString()
    },
    items: [{
      tag: XML_TAG.POINTS,
      items
    }]
  }).then(_ref2 => {
    let {
      query_id
    } = _ref2;
    return [query_id, url];
  });
}
function fetchDeviceHistoryConfig(url, user, unit, device, singlecfg, skipSessionUpdate) {
  if (singlecfg === void 0) {
    singlecfg = '0';
  }
  if (skipSessionUpdate === void 0) {
    skipSessionUpdate = false;
  }
  return fetchXMLData({
    url: getUnitUrl(url, unit),
    attributes: _objectSpread2({
      user: user.user,
      password: user.password,
      lang: user.language,
      version: 'C',
      storeview_only: '1',
      action: XML_ACTION.READ_DEVICE_HISTORY_CFG,
      nodetype: device.nodetype,
      singlecfg,
      optimization: '0',
      bpidx: Number(device.bpidx) > 0 ? Number(device.bpidx) - 1 : undefined,
      stype: device.stype,
      arg1: device.arg1,
      arg2: device.arg2,
      arg3: device.arg3,
      combo: device.combo,
      node: device.node,
      mod: device.mod,
      point: device.point
    }, skipSessionUpdate ? {
      session_update: 'no'
    } : {})
  });
}
function abortHistoryQuery(url, user, queryId) {
  return fetchXMLData({
    url,
    attributes: {
      version: 'C',
      storeview_only: '1',
      user: user.user,
      password: user.password,
      action: XML_ACTION.ABORT_QUERY,
      query_id: queryId
    }
  });
}
function abortAllHIstoryQueries(url, user) {
  return fetchXMLData({
    url,
    attributes: {
      version: 'C',
      storeview_only: '1',
      user: user.user,
      password: user.password,
      action: XML_ACTION.ABORT_QUERY,
      all: '1'
    }
  });
}

export { readHistoryQueryStatus as a, abortHistoryQuery as b, abortAllHIstoryQueries as c, fetchDeviceHistoryConfig as f, getHistoryFileChunk as g, readQueryData as r, startHistoryQuery as s };
