import '../../_rollupPluginBabelHelpers-543c173f.js';
import './utils/execute-operation.js';
import { Condition } from './Condition.js';
import { bindMethods } from '../utils/bind-methods.js';
import { Subscribable } from '../utils/Subscribable.js';

class ConditionCollection extends Subscribable {
  get invertColors() {
    return this._invertColors;
  }
  set invertColors(nextInvertColors) {
    this._invertColors = nextInvertColors;
    this.notify();
  }
  get gradientColors() {
    return this._gradientColorts;
  }
  set gradientColors(nextGradientColors) {
    this._gradientColorts = nextGradientColors;
    this.notify();
  }
  constructor() {
    super();
    this.conditions = [];
    this.gradientColors = false;
    this.invertColors = false;
    bindMethods(this, ['addCondition', 'removeCondition']);
    Object.defineProperties(this, {
      addCondition: {
        enumerable: false
      },
      removeCondition: {
        enumerable: false
      }
    });
  }
  addCondition() {
    var _this$conditions;
    const lastId = (_this$conditions = this.conditions[this.conditions.length - 1]) === null || _this$conditions === void 0 ? void 0 : _this$conditions.id;
    const id = typeof lastId === 'number' ? lastId + 1 : 0;
    const newCondition = new Condition(id);
    this.conditions.push(newCondition);
    this.notify();
    return newCondition;
  }
  importConditions(raw) {
    if (raw.gradientColors) {
      this.gradientColors = raw.gradientColors;
    }
    if (raw.invertColors) {
      this.invertColors = raw.invertColors;
    }
    if (Array.isArray(raw.conditions)) {
      raw.conditions.forEach(rawObj => {
        const newCondition = new Condition();
        newCondition.import(rawObj);
        this.conditions.push(newCondition);
      });
    }
    return this;
  }
  removeCondition(conditionId) {
    this.conditions = this.conditions.filter(cond => cond.id !== conditionId);
    this.notify();
    return this;
  }
  getConditionById(conditionId) {
    return this.conditions.find(cond => cond.id === conditionId);
  }
  getConditions() {
    return this.conditions;
  }
  toJSON() {
    return {
      gradientColors: this.gradientColors,
      invertColors: this.invertColors,
      conditions: this.conditions || []
    };
  }
}

export { ConditionCollection };
