import { _ as _objectSpread2 } from '../_rollupPluginBabelHelpers-15308e59.js';
import { DeviceGroupedType } from '../types/DeviceGrouped.js';
import { composeDeviceId } from './compose-device-id.js';

function getGroupedOtherDevices(deviceType, devices) {
  return devices.map(device => {
    return _objectSpread2(_objectSpread2({}, device), {}, {
      groupType: DeviceGroupedType.DEVICE_ONLY,
      id: composeDeviceId(device),
      deviceType,
      items: []
    });
  });
}

export { getGroupedOtherDevices };
