import '../../../_rollupPluginBabelHelpers-15308e59.js';
import '@danfoss/etui-sm-xml';
import '../../../constants/session-storage-constants.js';
import '../../../constants/software-versions.js';
import { useCallback } from 'react';
import 'use-deep-compare-effect';
import '../utils/get-xml-backend-ip.js';
import '../utils/get-xml-backend-protocol.js';
import '../utils/get-xml-proxy-config.js';
import '../utils/set-xml-proxy-config.js';
import '../app-context.js';
import { useApp } from './use-app.js';

function useXmlResource() {
  const [{
    xmlBackendIp: ip,
    protocol
  }, dispatch] = useApp();
  const url = `${protocol}//${ip}/xml.cgi`;
  const setIp = useCallback(newIp => {
    dispatch({
      type: 'SET_BE_IP',
      payload: newIp
    });
  }, [dispatch]);
  return {
    url,
    ip,
    setIp
  };
}

export { useXmlResource };
