import '../../constants/regex-patterns-constant.js';
import '../../constants/session-storage-constants.js';
import { XML_ACTION } from '../../constants/xml-action-constant.js';
import '../../constants/xml-tag-constant.js';
import '../../types/Auth.js';
import '../../types/Unit.js';
import { _ as _objectSpread2 } from '../../_rollupPluginBabelHelpers-6d49b19d.js';
import 'xml2js';
import '../../helpers/xml-helpers.js';
import '../../helpers/session-token-helpers.js';
import '../../helpers/trim-url-ending.js';
import '../../helpers/metadata-helpers.js';
import '../../helpers/header-helpers.js';
import '../../helpers/is-allowed-server-error.js';
import '../../helpers/proxy-helpers.js';
import '../../helpers/url-helpers/get-is-valid-dns.js';
import '../../helpers/url-helpers/get-is-valid-ip.js';
import '../../helpers/url-helpers/get-is-valid-ip-or-dns.js';
import '../../helpers/url-helpers/get-is-valid-port.js';
import '../../helpers/url-helpers/get-url-info.js';
import { isCurrentlySelectedUnit } from '../../helpers/unit-helpers/initial-unit-utils.js';
import '../../helpers/unit-helpers/get-unit-networks-by-unit.js';
import '../../helpers/unit-helpers/get-unit-network-by-unit.js';
import '../../helpers/unit-helpers/get-unit-url-by-unit-network.js';
import { getUnitUrl } from '../../helpers/unit-helpers/get-unit-url.js';
import '../../helpers/xml-error.js';
import '../../proxy-config.js';
import 'csvtojson';
import '../../fetch-xml/fetch-xml.js';
import { fetchXMLData } from '../../fetch-xml/fetch-xml-data.js';

async function fetchDeviceValueByUnits(sourceUrl, units, device, user, skipSessionUpdate) {
  const unit = units.find(u => u.unit_addr === device.host);
  if (!unit) {
    throw new Error(`No units found for the device host: ${device.host}`);
  }
  let bpidx = 0;
  if (parseInt(device.bpidx, 10) > 0) {
    bpidx = parseInt(device.bpidx, 10) - 1;
  }
  const data = await fetchXMLData({
    url: getUnitUrl(sourceUrl, unit),
    attributes: _objectSpread2({
      arg1: device.arg1,
      arg2: device.arg2,
      arg3: device.arg3,
      bpidx: bpidx.toString(),
      node: device.node,
      nodetype: device.nodetype,
      stype: device.stype,
      mod: device.mod,
      point: device.point,
      combo: device.combo,
      user: user.user,
      password: user.password,
      lang: user.language,
      action: XML_ACTION.READ_CS_DEVICE_VALUE
    }, skipSessionUpdate && isCurrentlySelectedUnit(unit) ? {
      session_update: 'no'
    } : {})
  });
  return data.devicevalue;
}

export { fetchDeviceValueByUnits };
