import '../../constants/regex-patterns-constant.js';
import '../../constants/session-storage-constants.js';
import '../../constants/xml-action-constant.js';
import { XML_DEVICE_LIST, XML_DEVICE_STYPE } from '../../constants/xml-device-constant.js';
import '../../constants/xml-tag-constant.js';
import '../../helpers/device-helpers.js';
import '../../types/Auth.js';
import '../../types/Unit.js';
import '../../_rollupPluginBabelHelpers-6d49b19d.js';
import 'xml2js';
import '../../helpers/xml-helpers.js';
import '../../helpers/session-token-helpers.js';
import '../../helpers/trim-url-ending.js';
import '../../helpers/metadata-helpers.js';
import '../../helpers/header-helpers.js';
import '../../helpers/is-allowed-server-error.js';
import '../../helpers/proxy-helpers.js';
import '../../helpers/url-helpers/get-is-valid-dns.js';
import '../../helpers/url-helpers/get-is-valid-ip.js';
import '../../helpers/url-helpers/get-is-valid-ip-or-dns.js';
import '../../helpers/url-helpers/get-is-valid-port.js';
import '../../helpers/url-helpers/get-url-info.js';
import { isCurrentlySelectedUnit } from '../../helpers/unit-helpers/initial-unit-utils.js';
import '../../helpers/unit-helpers/get-unit-networks-by-unit.js';
import '../../helpers/unit-helpers/get-unit-network-by-unit.js';
import '../../helpers/unit-helpers/get-unit-url-by-unit-network.js';
import '../../helpers/unit-helpers/get-unit-url.js';
import { getUnitsByDeviceType } from '../../helpers/unit-helpers/get-units-by-device-type.js';
import '../../helpers/xml-error.js';
import '../../proxy-config.js';
import './fetch-hvac-devices-by-unit.js';
import './fetch-misc-devices-by-unit.js';
import { fetchDevicesByUnit } from './fetch-devices-by-unit.js';
import 'csvtojson';
import '../../fetch-xml/fetch-xml.js';
import '../../fetch-xml/fetch-xml-data.js';

async function fetchDevicesByUnits(_ref) {
  let [sourceUrl, units, deviceType, user, skipSessionUpdate = false] = _ref;
  const unitsWithDeviceType = getUnitsByDeviceType(units, deviceType);
  const data = await Promise.all(unitsWithDeviceType.map(unit => fetchDevicesByUnit(sourceUrl, unit, deviceType, user, skipSessionUpdate && isCurrentlySelectedUnit(unit))));
  const devices = data.reduce((r, _ref2) => {
    let {
      device
    } = _ref2;
    return r.concat(Array.isArray(device) ? device.map(d => d) : [device]);
  }, []);
  if (deviceType === XML_DEVICE_LIST.MISC) {
    return devices.filter(device => device.stype !== XML_DEVICE_STYPE.S_TYPE_METER);
  }
  return devices;
}

export { fetchDevicesByUnits };
