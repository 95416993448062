import * as React from 'react';
import useSWR from 'swr';
import { useAuth } from '@danfoss/etui-sm/context/auth';
import { useXmlResource } from '@danfoss/etui-sm/context/app';
import {
  Device,
  Unit,
  User,
  XML_DEVICE_COMBO,
  XML_DEVICE_LIST,
  XML_DEVICE_STYPE,
  fetchDevicesByUnit,
} from '@danfoss/etui-sm-xml';
import {
  getDeviceTypeByComboAndSType,
  getDevicesByCombo,
} from '../components/ConfigurationItemContentMenuDeviceSelect/utils';

interface FetchDeviceParams {
  unit: Unit;
  combo: XML_DEVICE_COMBO;
  stype: XML_DEVICE_STYPE;
}

const filterMcxDevices = lightDevices =>
  lightDevices.filter(
    ({ combo }: Device) => combo === XML_DEVICE_COMBO.COMBO_MCX,
  );

async function fetchDevicesByComboAndSType([
  sourceUrl,
  unit,
  combo,
  stype,
  user,
]: [string, Unit, XML_DEVICE_COMBO, XML_DEVICE_STYPE, User]) {
  if (combo === XML_DEVICE_COMBO.COMBO_GENERIC) {
    const deviceTypes: XML_DEVICE_LIST[] = [
      XML_DEVICE_LIST.REFRIG,
      XML_DEVICE_LIST.HVAC,
      XML_DEVICE_LIST.MISC,
      XML_DEVICE_LIST.LIGHTS,
    ];

    const deviceRequests = deviceTypes.map(type =>
      fetchDevicesByUnit(sourceUrl, unit, type, user),
    );

    const allDevicesResponse = await Promise.allSettled<
      Promise<{ device: Device[] }>[]
    >(deviceRequests);

    return allDevicesResponse.reduce((accumulator, response, index) => {
      if (response.status === 'rejected') throw new Error(response.reason);

      const devices = response.value.device || [];
      const processedDevices = Array.isArray(devices) ? devices : [devices];
      const isLightDevices = index === deviceTypes.length - 1;
      return accumulator.concat(
        isLightDevices ? filterMcxDevices(processedDevices) : processedDevices,
      );
    }, []);
  }

  const deviceType = getDeviceTypeByComboAndSType(combo, stype);
  const data = await fetchDevicesByUnit(sourceUrl, unit, deviceType, user);

  if (data && combo === XML_DEVICE_COMBO.COMBO_MCX) {
    return filterMcxDevices(data.device);
  }

  return data?.device || [];
}

const useConfigDevices = ({ unit, combo, stype }: FetchDeviceParams) => {
  const { user } = useAuth();
  const { url } = useXmlResource();

  const noDevices =
    !combo ||
    [XML_DEVICE_COMBO.COMBO_NONE, XML_DEVICE_COMBO.COMBO_UNITS].some(
      type => type === combo,
    );

  const { data, error, isValidating, mutate } = useSWR<Device[]>(
    noDevices ? null : [url, unit, combo, stype, user],
    fetchDevicesByComboAndSType,
    {
      revalidateOnFocus: false,
      shouldRetryOnError: true,
    },
  );

  const isLoading = !data && !error && isValidating;
  const devices: Device[] = React.useMemo(
    () =>
      data
        ? getDevicesByCombo(
            Array.isArray(data) ? data.filter(Boolean) : [data],
            combo,
          )
        : [],
    [data, combo],
  );

  return { devices, isLoading, error, mutate };
};

export { useConfigDevices };
