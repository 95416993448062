import { _ as _objectSpread2 } from '../_rollupPluginBabelHelpers-15308e59.js';
import { XML_DEVICE_STR } from '@danfoss/etui-sm-xml';
import { DeviceGroupedType } from '../types/DeviceGrouped.js';
import { getIsMcxLightDevice } from './get-is-mcx-light-device.js';
import { getIsLightZoneDevice } from './get-is-light-zone-device.js';

function getGroupedLightingDevices(deviceType, devices) {
  const lightZones = [];
  const zoneDevices = devices.filter(getIsLightZoneDevice);
  const lightDevices = devices.filter(device => {
    if (device.type === XML_DEVICE_STR.STR_TYPE_LIGHTING && (device.arg1 || device.arg2) && !getIsMcxLightDevice(device)) {
      return true;
    }
    return false;
  }).map(device => _objectSpread2(_objectSpread2({}, device), {}, {
    id: device.arg2 ? `${device.host}-${device.arg1}-${device.arg2}` : `${device.host}-${device.arg1}`,
    groupType: DeviceGroupedType.DEVICE_ONLY,
    deviceType,
    items: []
  }));
  if (zoneDevices.length === 0) {
    return lightDevices;
  }
  for (let index = 0; index < zoneDevices.length; index++) {
    const lightZone = _objectSpread2(_objectSpread2({}, zoneDevices[index]), {}, {
      groupType: DeviceGroupedType.GROUP_DEVICE,
      deviceType
    });
    lightZone.id = `${lightZone.host}-${lightZone.arg1}`;
    const lightZoneItems = [];
    for (let lightDevicesIndex = 0; lightDevicesIndex < lightDevices.length; lightDevicesIndex++) {
      const lightDevice = lightDevices[lightDevicesIndex];
      const lightDeviceIdItems = lightDevice.id.split('-');
      const lightZoneIdItems = lightZone.id.split('-');
      if (lightDeviceIdItems[0] === lightZoneIdItems[0] && lightDeviceIdItems[1] === lightZoneIdItems[1] && !getIsMcxLightDevice(lightZone)) {
        lightZoneItems.push(lightDevice);
      }
    }
    lightZone.items = lightZoneItems;
    lightZones.push(lightZone);
  }
  return lightZones;
}

export { getGroupedLightingDevices };
