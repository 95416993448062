import '../constants/regex-patterns-constant.js';
import '../constants/session-storage-constants.js';
import { XML_ACTION } from '../constants/xml-action-constant.js';
import { XML_TAG } from '../constants/xml-tag-constant.js';
import '../types/Auth.js';
import '../types/Unit.js';
import { a as _objectWithoutProperties, _ as _objectSpread2 } from '../_rollupPluginBabelHelpers-6d49b19d.js';
import 'xml2js';
import '../helpers/xml-helpers.js';
import '../helpers/session-token-helpers.js';
import '../helpers/trim-url-ending.js';
import '../helpers/metadata-helpers.js';
import '../helpers/header-helpers.js';
import '../helpers/is-allowed-server-error.js';
import '../helpers/proxy-helpers.js';
import '../helpers/url-helpers/get-is-valid-dns.js';
import '../helpers/url-helpers/get-is-valid-ip.js';
import '../helpers/url-helpers/get-is-valid-ip-or-dns.js';
import '../helpers/url-helpers/get-is-valid-port.js';
import '../helpers/url-helpers/get-url-info.js';
import '../helpers/unit-helpers/initial-unit-utils.js';
import '../helpers/unit-helpers/get-unit-networks-by-unit.js';
import '../helpers/unit-helpers/get-unit-network-by-unit.js';
import '../helpers/unit-helpers/get-unit-url-by-unit-network.js';
import { getUnitUrl } from '../helpers/unit-helpers/get-unit-url.js';
import '../helpers/xml-error.js';
import '../proxy-config.js';
import { getFile } from './file-actions.js';
import 'csvtojson';
import '../fetch-xml/fetch-xml.js';
import { fetchXMLData } from '../fetch-xml/fetch-xml-data.js';

const _excluded = ["progressCb"],
  _excluded2 = ["progressCb"];

// Controller accepts 128kB size chunks.
// The constant here is less to be safe.
const byteSizeLimit = 100000;
const DB_RAMDISK = 'db_ramdisk';
function getDbChunk(encodedDbString, numOfChunks, chunksLeft) {
  const chunkNumber = numOfChunks - chunksLeft;
  const chunkSize = Math.ceil(encodedDbString.length / numOfChunks);
  return encodedDbString.substr(chunkNumber * chunkSize, chunkSize);
}

/*
  Atomic actions
*/
function loadDataBaseRequest(url, unit, user, filename, description, file_id, index, done, size, offset, b64) {
  if (index === void 0) {
    index = 1;
  }
  if (done === void 0) {
    done = 0;
  }
  try {
    return fetchXMLData({
      url: getUnitUrl(url, unit),
      attributes: {
        user: user.user,
        password: user.password,
        lang: user.language,
        action: XML_ACTION.LOAD_DATABASE,
        filename,
        description,
        file_id,
        index,
        done,
        size,
        offset
      },
      items: [{
        tag: XML_TAG.B64,
        value: b64
      }]
    });
  } catch (e) {
    throw new Error();
  }
}
function getDataBaseRequest(_ref) {
  let {
    url,
    unit,
    user,
    offset = '0',
    description,
    filename
  } = _ref;
  return fetchXMLData({
    url: getUnitUrl(url, unit),
    attributes: {
      user: user.user,
      password: user.password,
      lang: user.language,
      action: XML_ACTION.GET_DATABASE,
      offset,
      description,
      filename
    }
  });
}
/*
  End Atomic actions
*/

async function getDataBase(getDbConfigObject) {
  const {
      progressCb = () => {}
    } = getDbConfigObject,
    requestBody = _objectWithoutProperties(getDbConfigObject, _excluded);
  let loadedBytes = 0;
  let done = '0';
  let offset = '0';
  let encodedDb = '';
  while (done !== '1') {
    const {
      done: nextDone,
      offset: nextOffset,
      enc_bytes,
      num_bytes,
      db: {
        b64
      }
    } = await getDataBaseRequest(_objectSpread2(_objectSpread2({}, requestBody), {}, {
      offset
    }));
    loadedBytes += Number(num_bytes);
    done = nextDone;
    // offset from request is returned as an attribute
    // and offset from response is inside of offset tag
    offset = Array.isArray(nextOffset) ? nextOffset[nextOffset.length - 1] : nextOffset;
    encodedDb += b64;
    progressCb(Math.floor(loadedBytes / Number(enc_bytes) * 100));
  }
  progressCb(100);
  return encodedDb;
}
function readFileAsync(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => {
      resolve(reader.result);
    };
    reader.onerror = reject;
    reader.readAsDataURL(file);
  });
}
async function loadDataBase(filename, description, offset, db, user, url, unit, progressSuccessCb) {
  const base64 = await readFileAsync(db);
  const b64 = base64.split('base64,').pop();
  const b64FileSliced = b64;
  const fileLength = b64FileSliced.length;
  const chunksNum = Math.ceil(fileLength / byteSizeLimit);
  let chunksLeft = chunksNum;
  let index = 1;
  let done = 1;
  let file_id;
  let size;
  try {
    do {
      done = chunksLeft === 1 ? 1 : 0;
      size = fileLength;
      const chunkDB64 = getDbChunk(b64, chunksNum, chunksLeft);
      const {
        file_id: nextFileId,
        error,
        error_msg,
        operation
      } = await loadDataBaseRequest(url, unit, user, filename, description, file_id, index, done, size, offset, chunkDB64);
      file_id = nextFileId;
      if (done === 1 && (operation === null || operation === void 0 ? void 0 : operation._) === 'Success') {
        progressSuccessCb(25, true);
      }
      if (error && error_msg || done === 1 && (operation === null || operation === void 0 ? void 0 : operation._) === 'Failure') {
        throw new Error();
      }
      index++;
      chunksLeft--;
    } while (chunksLeft > 0);
  } catch (e) {
    throw new Error();
  }
}

/**
 * SM800A
 */
function createDbPackage(_ref2) {
  let {
    url,
    unit,
    user
  } = _ref2;
  return fetchXMLData({
    url: getUnitUrl(url, unit),
    attributes: {
      user: user.user,
      password: user.password,
      lang: user.language,
      action: XML_ACTION.UPDATE_SW,
      sw_upgrade_cmd: 'export_database'
    }
  });
}
function getDbFile(_ref3) {
  let {
    url,
    user
  } = _ref3;
  return getFile({
    url,
    user,
    filename: 'smDatabase.dpk',
    sql_db: '2',
    access_area: DB_RAMDISK
  });
}
async function getDataBaseSM800A(getDbConfigObject) {
  const requestBody = _objectWithoutProperties(getDbConfigObject, _excluded2);
  await createDbPackage(requestBody);
  return getDbFile(requestBody);
}

export { getDataBase, getDataBaseRequest, getDataBaseSM800A, getDbChunk, loadDataBase, loadDataBaseRequest, readFileAsync };
