import { XML_ACTION } from './constants/xml-action-constant.js';
import { _ as _objectSpread2 } from './_rollupPluginBabelHelpers-6d49b19d.js';
import './helpers/xml-helpers.js';
import { getUnitUrl } from './helpers/unit-helpers/get-unit-url.js';
import 'csvtojson';
import { fetchXMLData } from './fetch-xml/fetch-xml-data.js';

async function fetchNonDeviceHistoryCfg(sourceUrl, unit, user, skipSessionUpdate) {
  const NON_DEVICE_BPIDX = 0;
  const NON_DEVICE_NODETYPE = 255;
  const action = XML_ACTION.READ_HISTORY_CFG;
  const url = getUnitUrl(sourceUrl, unit);
  const res = await fetchXMLData({
    url,
    attributes: _objectSpread2({
      user: user.user,
      password: user.password,
      lang: user.language,
      storeview_only: '1',
      version: 'C',
      action
    }, skipSessionUpdate ? {
      session_update: 'no'
    } : {})
  });
  if (res.history_cfg) {
    const otherData = res === null || res === void 0 ? void 0 : res.history_cfg.filter(item => +item.bpidx === NON_DEVICE_BPIDX && +item.nodetype === NON_DEVICE_NODETYPE).map(cfg => {
      return _objectSpread2(_objectSpread2({}, cfg), {}, {
        url
      });
    });
    return otherData;
  }
  return [];
}

export { fetchNonDeviceHistoryCfg as f };
