import '../../../../../_rollupPluginBabelHelpers-15308e59.js';
import '@danfoss/etui-sm-xml';
import '@danfoss/etui-core';
import '../../../../../constants/session-storage-constants.js';
import '../../../../../constants/software-versions.js';
import '../../../../../constants/software-update-type.js';
import '../../../../../constants/index.js';
import '../../../../../utils/get-software-version-prefix.js';
import '../../../../../utils/get-is-matched-software-version.js';
import '../../../../../utils/get-language-code-by-name.js';
import '../../../../../utils/get-measurements-by-unit.js';
import '../../../../../utils/get-user-from-storage.js';
import '../../../../../utils/compare-software-versions.js';
import '../../../../../utils/get-version-from-filename.js';
import '../../../../../actions/authorize-units.js';
import '../../../../../actions/fetch-initial-unit.js';
import '../../../../../actions/fetch-metadata-files.js';
import '../../../../../actions/fetch-other-units.js';
import '../../../../../utils/get-is-misc-device.js';
import React from 'react';
import 'react-i18next';
import 'swr';
import '@danfoss/etui-system';
import '../../../../../context/modal/modal-context.js';
import '../../../../../context/modal/use-modal.js';
import 'use-deep-compare-effect';
import '../../../../../context/app/utils/get-xml-backend-ip.js';
import '../../../../../context/app/utils/get-xml-backend-protocol.js';
import '../../../../../context/app/utils/get-xml-proxy-config.js';
import '../../../../../context/app/utils/set-xml-proxy-config.js';
import '../../../../../context/app/app-context.js';
import '../../../../../context/app/hooks/use-app.js';
import '../../../../../context/app/hooks/use-xml-resource.js';
import '@danfoss/etui-sm-i18n';
import '../../../../../context/auth/utils/logout-clean-up.js';
import '../../../../../context/auth/utils/set-user-to-storage.js';
import '../../../../../context/auth/auth-context.js';
import { useAuth } from '../../../../../context/auth/hooks/use-auth.js';
import 'styled-components';
import '../../../../../context/unit/utils/duplicate-session-info.js';
import '../../../../../context/unit/unit-actions.js';
import '../../../../../context/unit/unit-context.js';
import 'lodash.isequal';
import 'react-device-detect';
import 'featurehub-javascript-client-sdk';
import '../../../../../components/SwrGlobal/SwrGlobal.js';
import { useAsync } from '../../../../../hooks/use-async.js';
import 'match-sorter';
import '../context/SoftwareUpdateContext.js';
import '../context/hooks/use-multi-unit-update.js';
import '../../../../../use-update-type-values-7b8b1702.js';
import 'moment';
import '../actions/fetch-update-software-security-check.js';
import '../actions/fetch-update-software-unzip-file.js';
import '../actions/fetch-update-software-check.js';
import '../actions/fetch-db-s55-load.js';
import '../actions/fetch-update-software-danux-check.js';
import '../utils/read-file-async.js';
import '../actions/fetch-upload-file.js';
import './use-xml-file-upload.js';
import 'jszip';
import 'path';
import '@danfoss/etui-sm';
import '@danfoss/etui-sm/actions';
import { useKeepSessionAlive } from './use-keep-session-alive.js';
import { useSoftwareCheck } from './use-software-check.js';
import { fetchEdfFiles } from '../actions/fetch-edf-files.js';
import { getCombinedEdfFilesList } from '../utils/get-combined-edf-files-list.js';

function useEdfUpdate(_ref) {
  let {
    url,
    file,
    startProcess
  } = _ref;
  useKeepSessionAlive();
  const {
    user
  } = useAuth();
  const {
    isFailed: updateUploadError,
    isUploading: isUpdateUploadPending,
    fileUploadProgress: updateUploadProgress,
    isCompleted: updateUploadValue
  } = useSoftwareCheck(url, user, file, false, false, startProcess);
  const {
    execute: executeFetchUpdateFiles,
    pending: isFetchUpdateFilesPending,
    value: updateFetchData,
    error: updateFetchFilesError
  } = useAsync(() => fetchEdfFiles(url, user, true), false);
  const {
    execute: executeFetchInstalledFiles,
    pending: isFetchInstalledFilesPending,
    value: installedFetchData,
    error: installedFetchFilesError
  } = useAsync(() => fetchEdfFiles(url, user, false), false);
  React.useEffect(() => {
    if (updateUploadValue) {
      executeFetchUpdateFiles();
    }
  }, [updateUploadValue]);
  React.useEffect(() => {
    if (updateFetchData) {
      executeFetchInstalledFiles();
    }
  }, [updateFetchData]);
  const combinedFilesList = React.useMemo(() => getCombinedEdfFilesList(installedFetchData, updateFetchData), [updateFetchData, installedFetchData]);
  return {
    updateUploadProgress,
    isUpdateUploadPending,
    updateUploadValue,
    updateUploadError,
    isFetchUpdateFilesPending,
    updateFetchData,
    updateFetchFilesError,
    isFetchInstalledFilesPending,
    installedFetchData,
    installedFetchFilesError,
    combinedFilesList
  };
}

export { useEdfUpdate };
