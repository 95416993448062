import { a as _objectSpread2 } from '../../../_rollupPluginBabelHelpers-543c173f.js';

function graphicViewerReducer(state, action) {
  const {
    type
  } = action;
  switch (type) {
    case 'TOGGLE_PANEL':
      {
        return _objectSpread2(_objectSpread2({}, state), {}, {
          panelOpen: action.payload
        });
      }
    case 'UPDATE_PANEL_OPEN_ITEMS':
      {
        return _objectSpread2(_objectSpread2({}, state), {}, {
          panelOpenItems: action.payload
        });
      }
    default:
      {
        return state;
      }
  }
}

export { graphicViewerReducer };
