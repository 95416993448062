import { b as _objectWithoutProperties, _ as _objectSpread2 } from './_rollupPluginBabelHelpers-15308e59.js';
import { styled, css } from '@danfoss/etui-system';
import { Div } from '@danfoss/etui-system-elements';

const _excluded = ["theme"];
const CalendarContainerDiv = styled(Div).attrs(_ref => {
  let {
      theme
    } = _ref,
    props = _objectWithoutProperties(_ref, _excluded);
  return _objectSpread2({
    bg: theme.palette.common.white,
    p: theme.spacing.md
  }, props);
})`
  width: ${props => props.autoWidth ? 'auto' : '100%'};
  z-index: 101;

  ${_ref2 => {
  let {
    isMobile,
    alignRight,
    theme
  } = _ref2;
  if (isMobile) {
    return css`
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        height: 100%;
        display: flex;
        justify-content: space-around;
        flex-direction: column;
      `;
  }
  return css`
      display: flex;
      align-items: center;
      justify-content: space-between;
      position: absolute;
      left: ${alignRight ? 'auto' : 0};
      right: ${alignRight ? '0' : 'auto'};
      top: calc(${theme.shape.inputHeight}px + -1px);
      border: 1px solid ${theme.palette.grey[60]};
      border-top: 1px solid ${theme.palette.grey[40]};
      box-shadow: 0 7px 10px -5px rgba(0, 0, 0, 0.3);
    `;
}};
`;

const HaccpLegend = styled(Div)`
  width: 80px;
  margin-right: ${_ref => {
  let {
    theme
  } = _ref;
  return theme.spacing.md;
}}px;

  span {
    font-size: 11px;
    color: ${_ref2 => {
  let {
    theme
  } = _ref2;
  return theme.palette.text.secondary;
}};
`;

export { CalendarContainerDiv as C, HaccpLegend as H };
