import '../constants/xml-tag-constant.js';
import '../_rollupPluginBabelHelpers-6d49b19d.js';
import 'xml2js';
import { objToB64Uri } from './xml-helpers.js';

function createProxyUrl(_ref, url) {
  let {
    proxyAddress,
    deviceGuid,
    siteId,
    protocol,
    companyGuid
  } = _ref;
  const address = url.replace('https://', '').replace('http://', '').replace('/xml.cgi', '');
  const b64Payload = objToB64Uri({
    address,
    deviceGuid,
    siteId,
    protocol,
    companyGuid
  });
  return `https://${proxyAddress}/sm-xmlv2?q=${b64Payload}`;
}

export { createProxyUrl };
