import * as React from 'react';
import {
  XML_DEVICE_COMBO,
  Unit,
  ConfigurationTabItem,
  Device,
} from '@danfoss/etui-sm-xml';
import {
  CommandBar,
  CommandBarItemProps,
  SearchInput,
} from '@danfoss/etui-core';
import { useTheme } from '@danfoss/etui-system';
import { Div } from '@danfoss/etui-system-elements';
import { useTranslation } from 'react-i18next';
import debounce from 'lodash.debounce';
import { ConfigurationItemContentMenuPageSelect } from '../ConfigurationItemContentMenuPageSelect';
import { ConfigurationItemContentMenuDeviceSelect } from '../ConfigurationItemContentMenuDeviceSelect';
import { ConfigurationItemContentMenuDeviceGroupSelect } from '../ConfigurationItemContentMenuDeviceGroupSelect';
import { useConfigurationMenu } from '../../context';
import { ConfigurationItemContentMenuFoodGroupSelect } from '../ConfigurationItemContentMenuFoodGroupSelect';
import { useConfigDevices } from '../../hooks';
import { TABLE_ADDRESS } from '../../utils';

interface ConfigurationItemContentMenuProps {
  tab: ConfigurationTabItem;
  unit: Unit;
  menuId: string;
  device: Device;
  setDevice: (device: Device) => void;
  deviceGroup: string;
  setDeviceGroup: (group: string) => void;
  deviceSubgroup: string;
  setDeviceSubgroup: (subgroup: string) => void;
  page: string;
  setPage: (page: string) => void;
  multipage: string;
  groupnames: string[];
  subgroupnames: string[];
}

const tablesWithSearch: string[] = [TABLE_ADDRESS.HISTORY_SENSORS];

const ConfigurationItemContentMenu = ({
  tab,
  unit,
  menuId,
  device,
  setDevice,
  deviceGroup,
  setDeviceGroup,
  deviceSubgroup,
  setDeviceSubgroup,
  page,
  setPage,
  multipage,
  groupnames,
  subgroupnames,
}: ConfigurationItemContentMenuProps) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const searchInputStyles = {
    root: {
      maxWidth: '300px',
      width: '40%',
      borderRight: `1px solid ${theme.palette.divider}`,
    },
    input: {
      height: '100%',
      boxShadow: 'none !important',
      borderColor: 'transparent',
    },
  };
  const isCPT = tab?.combo === XML_DEVICE_COMBO.COMBO_CPT;

  const { combo, stype } = tab;
  const { devices, isLoading, mutate } = useConfigDevices({
    unit,
    combo,
    stype,
  });
  const { searchMode, searchValue, onSetSearchValue } = useConfigurationMenu();

  const [searchKey, setSearchKey] = React.useState<string>(searchValue);
  const debouncedSearch = React.useCallback(
    debounce(onSetSearchValue, 300),
    [],
  );
  const handleSearach = (value: string) => {
    setSearchKey(value);
    debouncedSearch(value);
  };

  const isSearchAvailable = React.useMemo(
    () => tablesWithSearch.includes(menuId),
    [menuId],
  );

  if (!device && devices?.length) setDevice(devices[0]);

  const handleOnDeviceChange = React.useCallback((selectedDevice: Device) => {
    setDevice(selectedDevice);
    setDeviceGroup('0');
    setDeviceSubgroup('0');
    setPage('0');
  }, []);

  const handleOnDeviceGroupChange = React.useCallback((group: string) => {
    setDeviceGroup(group);
    setDeviceSubgroup('0');
    setPage('0');
  }, []);

  const handleOnDeviceSubgroupChange = React.useCallback((subgroup: string) => {
    setDeviceSubgroup(subgroup);
    setPage('0');
  }, []);

  const hasMultipleDevices =
    tab &&
    [XML_DEVICE_COMBO.COMBO_NONE, XML_DEVICE_COMBO.COMBO_UNITS].every(
      combo => combo !== tab.combo,
    );
  const hasMultipleGroups = groupnames?.length > 1;
  const hasMultipleSubgroups = subgroupnames?.length > 1;
  const hasMultiplePages = multipage && multipage !== '0';

  const shouldDisplayMenuBar = [
    hasMultipleDevices,
    hasMultipleGroups,
    hasMultipleSubgroups,
    hasMultiplePages,
  ].some(Boolean);

  React.useEffect(() => {
    mutate();
  }, [menuId]);

  if (!shouldDisplayMenuBar) return null;

  const getDeviceMenu = () => {
    const deviceMenuItems: CommandBarItemProps[] = [];

    if (isSearchAvailable) {
      deviceMenuItems.push({
        key: 'list-item-search',
        onRender: item => (
          <SearchInput
            key={item.key}
            value={searchKey}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              handleSearach(e.target.value)
            }
            styles={searchInputStyles}
            placeholder={t('t3342')}
          />
        ),
      });
    }

    if (hasMultipleDevices) {
      deviceMenuItems.push({
        key: 'deviceSelect',
        onRender: () => (
          <ConfigurationItemContentMenuDeviceSelect
            combo={tab?.combo}
            devices={devices}
            selectedDevice={device || devices[0]}
            onDeviceChange={handleOnDeviceChange}
          />
        ),
      });
    }

    if (hasMultipleGroups) {
      deviceMenuItems.push({
        key: 'deviceGroupSelect',
        onRender: item => (
          <ConfigurationItemContentMenuDeviceGroupSelect
            key={item.key}
            name={item.key}
            selectedValue={deviceGroup}
            menugroups={groupnames}
            onChange={handleOnDeviceGroupChange}
          />
        ),
      });
    }

    if (hasMultipleSubgroups) {
      deviceMenuItems.push({
        key: 'deviceSubgroupSelect',
        onRender: item => (
          <ConfigurationItemContentMenuDeviceGroupSelect
            key={item.key}
            name={item.key}
            selectedValue={deviceSubgroup}
            menugroups={subgroupnames}
            onChange={handleOnDeviceSubgroupChange}
          />
        ),
      });
    }

    return deviceMenuItems;
  };

  const getPageMenu = () => {
    if (searchMode) return [];

    const pageMenuItems = [];

    if (hasMultiplePages) {
      pageMenuItems.push({
        key: 'pageSelect',
        onRender: item => (
          <ConfigurationItemContentMenuPageSelect
            key={item.key}
            selectedPage={page}
            isFullWidth={true}
            multipage={multipage}
            onPageChange={setPage}
          />
        ),
      });
    }

    return pageMenuItems;
  };

  const getFoodGroupMenu = () => {
    const CPTItems = [];
    if (isCPT) {
      CPTItems.push({
        key: 'CPTSelect',
        onRender: () => <ConfigurationItemContentMenuFoodGroupSelect />,
      });
    }
    return CPTItems;
  };

  const getItems = () => {
    if (isLoading) return [];

    return isCPT
      ? [...getPageMenu(), ...getFoodGroupMenu()]
      : [...getDeviceMenu(), ...getPageMenu(), ...getFoodGroupMenu()];
  };

  return (
    <Div
      border={`1px solid ${theme.palette.divider}`}
      borderLeft="0px"
      borderRight="0px"
    >
      <CommandBar
        items={getItems()}
        styles={{
          root: { m: null },
          leftSide: { width: '100%' },
        }}
      />
    </Div>
  );
};

export { ConfigurationItemContentMenu };
