import { _ as _objectSpread2 } from '../_rollupPluginBabelHelpers-15308e59.js';
import '@danfoss/etui-sm-xml';
import '../types/DeviceGrouped.js';
import './compose-device-id.js';
import './compose-rack-id.js';
import './get-is-suction-device.js';
import './get-is-no-compressor-device.js';
import './get-is-pack-device.js';
import './get-is-rack-device.js';
import './get-is-top-level-device.js';
import '../get-rack-groups-6c00696c.js';
import '@danfoss/etui-core';
import './get-normalized-suction.js';
import './get-suction-groups.js';
import { getGroupedRefrigerationDevices } from './get-grouped-refrigeration-devices.js';
import './get-is-mcx-light-device.js';
import './get-is-light-zone-device.js';
import { getGroupedLightingDevices } from './get-grouped-lighting-devices.js';
import { getGroupedOtherDevices } from './get-grouped-other-devices.js';
import { getIsMeterDevice } from './get-is-meter-device.js';
import './get-is-on-off-output-device.js';
import './get-is-relay-output-device.js';
import './get-is-sensor-input-device.js';
import './get-is-variable-output-device.js';
import { getGroupedMiscDevices } from './get-grouped-misc-devices.js';
import './get-array.js';
import './get-normalized-device-details.js';
import { getParametersFromDeviceDetails } from './get-parameters-from-device-details.js';

function getGroupedDevicesByDeviceType(deviceType, devices) {
  const normalizedDevices = devices.map(device => _objectSpread2(_objectSpread2({}, device), {}, {
    parameters: getParametersFromDeviceDetails(device)
  }));
  return normalizedDevices ? deviceType === 'refrig' ? getGroupedRefrigerationDevices(deviceType, normalizedDevices) : deviceType === 'lights' ? getGroupedLightingDevices(deviceType, normalizedDevices) : deviceType === 'meter' ? getGroupedOtherDevices(deviceType, normalizedDevices.filter(getIsMeterDevice)) : deviceType === 'misc' ? getGroupedMiscDevices(deviceType, normalizedDevices.filter(device => !getIsMeterDevice(device))) : getGroupedOtherDevices(deviceType, normalizedDevices) : [];
}

export { getGroupedDevicesByDeviceType };
