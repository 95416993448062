import { STORAGE_SESSION_TOKEN } from '../constants/session-storage-constants.js';
import { _ as _objectSpread2 } from '../_rollupPluginBabelHelpers-6d49b19d.js';

const getSessionTokensFromStorage = () => JSON.parse(sessionStorage.getItem(STORAGE_SESSION_TOKEN) || '{}');
const getSessionTokenByUrl = url => getSessionTokensFromStorage()[url];
const addSessionTokenByUrl = function (url, sessionToken) {
  if (sessionToken === void 0) {
    sessionToken = null;
  }
  const sessionTokens = getSessionTokensFromStorage();
  if (sessionTokens[url]) return;
  const payload = JSON.stringify(_objectSpread2(_objectSpread2({}, sessionTokens), {}, {
    [url]: sessionToken
  }));
  sessionStorage.setItem(STORAGE_SESSION_TOKEN, payload);
};
const removeSessionTokens = () => sessionStorage.removeItem(STORAGE_SESSION_TOKEN);
const getUrlsWithSessionTokens = () => {
  const sessionTokens = getSessionTokensFromStorage();
  const urlsWithSession = Object.entries(sessionTokens).reduce((accumulator, _ref) => {
    let [url, sessionToken] = _ref;
    return sessionToken ? accumulator.concat(url) : accumulator;
  }, []);
  return urlsWithSession;
};

export { addSessionTokenByUrl, getSessionTokenByUrl, getSessionTokensFromStorage, getUrlsWithSessionTokens, removeSessionTokens };
