import '../constants/session-storage-constants.js';
import '../constants/xml-tag-constant.js';
import { AKSM_AUTH_HEADER, AUTH_TYPE } from '../types/Auth.js';
import '../_rollupPluginBabelHelpers-6d49b19d.js';
import 'xml2js';
import { extractFromRequest } from './xml-helpers.js';
import { getSessionTokenByUrl } from './session-token-helpers.js';
import { trimUrlEnding } from './trim-url-ending.js';
import { getUnitMetadata } from './metadata-helpers.js';

const METADATA_BUILD_NUMBER = 'build_number';
const setAKSMAuthHeader = token => ({
  [AKSM_AUTH_HEADER]: token
});
const defineRequsetHeaders = (url, body) => {
  const isHttps = url.includes('https://');
  if (!isHttps) return {};
  const trimmedUrl = trimUrlEnding(url);
  const sessionToken = getSessionTokenByUrl(trimmedUrl);
  if (sessionToken) return setAKSMAuthHeader(sessionToken);
  const metadataFile = getUnitMetadata(trimmedUrl);
  const useAKSMAuthHeader = metadataFile && METADATA_BUILD_NUMBER in metadataFile;
  return useAKSMAuthHeader ? setAuthorizationHeader(body) : {};
};
const setAuthorizationHeader = requestBody => {
  const auth = extractFromRequest(requestBody, /auth="(.*?)"/);
  if (auth) return setAKSMAuthHeader(`${AUTH_TYPE.BASIC} ${auth}`);
  const username = extractFromRequest(requestBody, /user="(.*?)"/);
  const password = extractFromRequest(requestBody, /password="(.*?)"/);
  if (!username || !password) return {};
  const nameAndPasswordInBase64 = window.btoa(`${username}:${password}`);
  return setAKSMAuthHeader(`${AUTH_TYPE.BASIC} ${nameAndPasswordInBase64}`);
};
const removeUserDataFromBody = body => {
  return body.replace(/user="[^"]*"/, '').replace(/password="[^"]*"/, '');
};

export { defineRequsetHeaders, removeUserDataFromBody };
