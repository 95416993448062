import '../../constants/session-storage-constants.js';
import '../../constants/xml-action-constant.js';
import '../../constants/xml-error-code.js';
import '../../constants/xml-tag-constant.js';
import { getAlarmSummaryDeviceRefsByKey } from '../../helpers/alarm-helpers.js';
import '../../types/Auth.js';
import '../../_rollupPluginBabelHelpers-6d49b19d.js';
import 'xml2js';
import '../../helpers/xml-helpers.js';
import '../../helpers/session-token-helpers.js';
import '../../helpers/trim-url-ending.js';
import '../../helpers/metadata-helpers.js';
import '../../helpers/header-helpers.js';
import '../../helpers/is-allowed-server-error.js';
import '../../helpers/proxy-helpers.js';
import '../../helpers/xml-error.js';
import '../../proxy-config.js';
import { fetchDeviceAlarmSummary } from './fetch-device-alarm-summary.js';
import { fetchAlarmsByRefs } from '../alarm-actions/fetch-alarms-by-refs.js';
import 'csvtojson';
import '../../fetch-xml/fetch-xml.js';
import '../../fetch-xml/fetch-xml-data.js';

async function fetchDeviceAlarms(url, alarmType, user, device) {
  const alarmSummaryData = await fetchDeviceAlarmSummary(url, user, device);
  const alarmsRefs = getAlarmSummaryDeviceRefsByKey(alarmSummaryData, alarmType);
  if (alarmsRefs.length === 0) {
    return [];
  }
  return fetchAlarmsByRefs(url, alarmsRefs, user);
}

export { fetchDeviceAlarms };
